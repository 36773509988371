<template>
  <v-card class="ma-2 pa-3 p-relative" :class="{ 'mb-0': responsive, 'mb-3': !responsive }">
    <v-layout row wrap fill-height>
      <v-flex sm4 xs5 align-center>
        <TotalPie class="align-center" :allTotal="allTotal" :colors="colors" :labels="labels" :totals="totals" />
      </v-flex>
      <v-flex sm8 xs7 class="pl-3">
        <v-layout row wrap class="total-labels" fill-height>
          <v-flex v-for="(mode, index) in labels" :key="index" xs6 class="total-detail" align-center>
            <div class="ellipsis">
              <h2 :style="{ color: colors[index] }">{{ totals[index] | numeral("0,0") }}</h2>
              <span>{{ labels[index] }}</span>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-icon class="tooltip-icon total-tooltip" v-tooltip="{ content: tooltip, trigger: 'click hover' }">info</v-icon>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import TotalPie from "./../graphs/TotalPie";

export default {
  props: {
    colors: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    totals: {
      type: Array,
      required: true,
    },
    allTotal: {
      type: Number,
      default: 0,
      required: true,
    },
    tooltip: {
      type: String,
      default: `
		          <strong>Total registered devices and their power settings</strong>
		          <ul class="ma-0 mt-2 ml-3 pa-0">
		            <li class="mb-2">Smart: Allows you to control access, set billing rates,
		            monitor your<br>device's status and power consumption</li>
		            <li class="mb-2">Always On: Just like a regular device. Anyone can plug in</li>
		            <li>Always Off: Disables your device. Plugging in doesn't do anything</li>
		          </ul>`,
    },
  },
  components: {
    TotalPie,
  },
  computed: {
    ...mapState({
      responsive: (state) => state.responsive,
    }),
  },
};
</script>

<style lang="scss" scoped>
.total-labels {
  font-size: 14px;
  position: relative;
  border-radius: 3px;
  background: #f9f9f9;
  @media only screen and (max-width: 960px) {
    font-size: 0.9em;
  }
  div {
    width: 100%;
    text-align: center;
    background: #f9f9f9;
    overflow: hidden;
  }
}

.total-detail {
  padding: 1.8em;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    font-size: 0.9em;
    padding: 1.4em;
  }
}

.total-tooltip {
  position: absolute;
  top: 24px;
  right: 24px;
}
</style>
