var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"sm6":"","xs6":""}},[_c('v-card',{staticClass:"ma-2 stats-tile tile-sessions"},[(_vm.ready)?_c('agile',{ref:"statsTileCarousel",attrs:{"autoplay":"","options":_vm.statsTileCarouselOptions}},[_c('div',{staticClass:"slide pointer",attrs:{"ripple":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDetails('Sessions Log', 'session')}}},[_c('v-card-text',{staticClass:"d-flex"},[_c('h3',[_vm._v("Total Sessions")]),_c('v-spacer'),_c('div',{staticClass:"text-xs-right"},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: 'Total number of user sessions<br>over the selected filter and date range',
                  placement: 'top-center',
                  trigger: 'click hover',
                }),expression:"{\n                  content: 'Total number of user sessions<br>over the selected filter and date range',\n                  placement: 'top-center',\n                  trigger: 'click hover',\n                }"}],staticClass:"tooltip-icon stat-tooltip",attrs:{"id":"session-tile-info"}},[_vm._v("info")])],1)],1),_c('v-card-text',{staticClass:"text-xs-right py-1 px-4"},[(_vm.loading)?_c('h2',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":24}})],1):_c('h2',{attrs:{"id":"sessions-tile"}},[_vm._v(_vm._s(_vm._f("numeral")(_vm.totals.session,"0,0")))])])],1),_c('div',{staticClass:"slide pointer",attrs:{"ripple":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDetails('Sessions Log', 'session')}}},[_c('v-card-text',{staticClass:"d-flex"},[_c('h3',[_vm._v("Total Users")]),_c('v-spacer'),_c('div',{staticClass:"text-xs-right"},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: 'Total number of user sessions as well as unique users over the selected filter and date range',
                  placement: 'top-center',
                  trigger: 'click hover',
                }),expression:"{\n                  content: 'Total number of user sessions as well as unique users over the selected filter and date range',\n                  placement: 'top-center',\n                  trigger: 'click hover',\n                }"}],staticClass:"tooltip-icon stat-tooltip"},[_vm._v("info")])],1)],1),_c('v-card-text',{staticClass:"text-xs-right py-1 px-4"},[(_vm.loading)?_c('h2',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":24}})],1):_c('h2',{attrs:{"id":"total-users"}},[_vm._v(_vm._s(_vm._f("numeral")(_vm.totals.user,"0,0")))])])],1),_c('template',{slot:"prevButton"},[_c('v-icon',[_vm._v("navigate_before")])],1),_c('template',{slot:"nextButton"},[_c('v-icon',[_vm._v("navigate_next")])],1)],2):_vm._e()],1)],1),_c('v-flex',{attrs:{"sm6":"","xs6":""}},[_c('v-card',{staticClass:"ma-2 stats-tile tile-consumption pointer",on:{"click":function($event){$event.stopPropagation();return _vm.openDetails('Consumption Log', 'consumptionkWh')}}},[_c('v-card-text',{staticClass:"d-flex"},[_c('h3',[_vm._v("Total Consumption")]),_c('v-spacer'),_c('div',{staticClass:"text-xs-right"},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'Total Kilowatt hours consumed by all sessions over the selected filter and date range',
              placement: 'top-center',
              trigger: 'click hover',
            }),expression:"{\n              content: 'Total Kilowatt hours consumed by all sessions over the selected filter and date range',\n              placement: 'top-center',\n              trigger: 'click hover',\n            }"}],staticClass:"tooltip-icon stat-tooltip",attrs:{"id":"total-consumption-tile-info"}},[_vm._v("info")])],1)],1),_c('v-card-text',{staticClass:"text-xs-right py-1"},[(_vm.loading)?_c('h2',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":24}})],1):_c('h2',{attrs:{"id":"total-consumption-tile"}},[(_vm.totals.consumptionkWh >= 1000)?[_vm._v(_vm._s(_vm._f("numeral")(Math.floor(_vm.totals.consumptionkWh),"0,0")))]:[_vm._v(_vm._s(_vm._f("numeral")(_vm.totals.consumptionkWh,"0,0.000")))],_c('small',{staticClass:"ml-1"},[_vm._v("kWh")])],2)])],1)],1),_c('v-flex',{attrs:{"sm6":"","xs6":""}},[_c('v-card',{staticClass:"ma-2 stats-tile tile-duration pointer",on:{"click":function($event){$event.stopPropagation();return _vm.openDetails('Duration Log', 'durationHours')}}},[_c('v-card-text',{staticClass:"d-flex"},[_c('h3',[_vm._v("Total Duration")]),_c('v-spacer'),_c('div',{staticClass:"text-xs-right"},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'Total time span of all user sessions<br>over the selected filter and date range',
              placement: 'top-center',
              trigger: 'click hover',
            }),expression:"{\n              content: 'Total time span of all user sessions<br>over the selected filter and date range',\n              placement: 'top-center',\n              trigger: 'click hover',\n            }"}],staticClass:"tooltip-icon stat-tooltip",attrs:{"id":"total-duration-tile-info"}},[_vm._v("info")])],1)],1),_c('v-card-text',{staticClass:"text-xs-right py-1"},[(_vm.loading)?_c('h2',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":24}})],1):_c('h2',{attrs:{"id":"total-duration-tile"}},[_vm._v(_vm._s(_vm._f("numeral")(_vm.totals.duration,"00:00:00")))])])],1)],1),_c('v-flex',{attrs:{"sm6":"","xs6":""}},[(_vm.walletItems.length > 0 && _vm.stats.revenues.length > 0)?_c('v-card',{staticClass:"ma-2 stats-tile tile-cost p-relative"},[(_vm.ready && _vm.walletItems)?_c('WalletsCarousel',{attrs:{"wallet-items":_vm.walletItems,"loading":_vm.loading}}):_vm._e()],1):_c('v-card',{staticClass:"ma-2 stats-tile tile-cost p-relative",attrs:{"height":"90%"},on:{"click":function($event){$event.stopPropagation();return _vm.openDetails('Revenue', 'revenue')}}},[_c('v-card-text',{staticClass:"d-flex"},[_c('h3',[_vm._v("Total Revenue")]),_c('v-spacer'),_c('div',{staticClass:"text-xs-right"},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'Total earnings from all user sessions<br>over the selected filter and date range',
              placement: 'top-center',
              trigger: 'click hover',
            }),expression:"{\n              content: 'Total earnings from all user sessions<br>over the selected filter and date range',\n              placement: 'top-center',\n              trigger: 'click hover',\n            }"}],staticClass:"tooltip-icon stat-tooltip",attrs:{"id":"revenue-tile-info"}},[_vm._v("info")])],1)],1),_c('v-card-text',{staticClass:"text-xs-right py-1"},[(_vm.loading)?_c('h2',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":24}})],1):_c('h2',{attrs:{"id":"total-revenue-tile"}},[_vm._v("N/A")])])],1)],1),_c('TotalStatsGraph',{attrs:{"selected":_vm.selectedStat}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }