<template>
  <div id="time-based-pricing">
    <p class="mb-1">Pricing for the next 24 hours:</p>
    <!-- <v-timeline v-if="pricings.length > 1" dense class="pt-0"> -->
    <v-timeline dense class="pt-0">
      <v-timeline-item color="black" small v-for="(pricing, i) in pricings" :key="i">
        <v-layout>
          <v-flex>
            <span class="font-weight-bold">{{ pricing.text }}</span>

            <!-- <div v-if="pricing.plan.disabled" class="error--text">
              Device is disabled during this time period. Sessions will automatically terminate. Please contact management for more information.
            </div> -->
            <!-- <div v-else> -->
            <div>
              <p class="mb-0" v-html="pricing.planText" />
            </div>
          </v-flex>
        </v-layout>
      </v-timeline-item>
    </v-timeline>
    <!-- <div v-else-if="pricings.length === 1" class="pl-4">
      <div v-if="pricings[0].plan.startSessionCost > 0 || isFree(pricings[0].plan)">
        Activation Fee <span v-if="currency">({{ currency }})</span>:
        <span class="font-weight-medium">{{ formatCost(pricings[0].plan.startSessionCost || 0) }}</span>
      </div>
      <div v-if="pricings[0].plan.hRate > 0 || isFree(pricings[0].plan)">
        Duration Rate <span v-if="currency">({{ currency }})</span>:
        <span class="font-weight-medium">{{ formatRate(pricings[0].plan.hRate || 0) }}</span>
      </div>
      <div v-if="pricings[0].plan.whRate > 0 || isFree(pricings[0].plan)">
        Consumption Rate <span v-if="currency">({{ currency }})</span>:
        <span class="font-weight-medium">{{ formatRate(((pricings[0].plan.whRate || 0) * 1000000000) / 1000000, "kWh") }}</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import { calculatePricing } from "@/library/helpers/paymentPlan"
import mixins from "@/mixins";
export default {
  name: 'ConditionalPlanPricing',
  mixins: [mixins],
  props: {
    paymentPlan: {
      type: Object,
      required: true,
    },
    currency: String,
  },
  data: () => ({
    pricings: [],
  }),
  computed: {
    timezone() {
      return this.paymentPlan.timezone ? this.paymentPlan.timezone : "UTC";
    },
  },
  methods: {
    isFree(plan) {
      return !(plan.startSessionCost && plan.hRate && plan.whRate)
    }
  },
  mounted() {
    this.pricings = calculatePricing({ paymentPlan: this.paymentPlan, currency: this.currency });
  },
};
</script>
