<template />

<script>
import { mapState, mapGetters } from 'vuex'
import { capitalize } from "lodash-es"
import { Capacitor } from "@capacitor/core"
export default {
  name: "Intercom",
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
      location: (state) => state.Global.location,
      intercom: (state) => state.Global.intercom,
      authenticatedEmail: (state) => state.Auth.email,
    }),
    ...mapGetters("Auth", ["isAuthenticated"]),
    isVisible() { return this.$intercom.visible },
    user_id() { return `${this.isAuthenticated ? this.role : "unknown"}-${this.authenticatedEmail || this.intercom.email || "undefined"}` },
    intercomConfig() {
      return {
        user_id: this.user_id,
        name: this.user_id,
        email: this.authenticatedEmail || this.intercom.email || "undefined",
        environment: capitalize(process.env.VUE_APP_SERVER),
        "User Type": capitalize(this.role || "-"),
        location: `${this.location.latitude && this.location.longitude ? `${this.location.latitude}, ${this.location.longitude}` : "-"}`
      }
    }
  },
  watch: {
    isVisible() {
      document.body.classList[this.isVisible ? "remove" : "add"](`intercom-hide-bubble`)
      if (this.isVisible !== this.intercom.show) this.$store.commit("Global/setState", { intercom: { ...this.intercom, show: this.isVisible } })
    },
    location: {
      handler() {
        this.$intercom.update({
          location: `${this.location.latitude && this.location.longitude ? `${this.location.latitude}, ${this.location.longitude}` : "-"}`
        })
      },
      deep: true
    },
    intercomConfig: {
      handler() {
        this.$nextTick(() => this.$intercom.update(this.intercomConfig))
      },
      deep: true
    },
    "intercom.show"() {
      if (this.isVisible !== this.intercom.show) this.$intercom[this.$intercom.visible ? "hide" : "show"]()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$intercom.boot(this.intercomConfig)
    });
    this.$nextTick(() => {
      document.body.classList.add(`intercom-hide-bubble`);
      console.log("capacitor", Capacitor.getPlatform(), Capacitor.isNativePlatform())
      if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === "ios") {
        document.body.classList.add(`intercom-ios`);
      }
    })
  },
  onUnmounted() {
    document.body.classList.remove(`intercom-hide-bubble`);
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === "ios") {
      document.body.classList.remove(`intercom-ios`);
    }
    this.$nextTick(() => {
      this.$intercom.shutdown()
      document.cookie = document.cookie.split(" ").filter(s => s.includes("intercom")).map(s => `${s.split("=")[0]}=;`).join(" ")
    })
  }
}
</script>

<style lang="scss">
.intercom-hide-bubble {
  .intercom-lightweight-app, .intercom-app {
    display: none !important;
  }
}
.intercom-ios {
  .intercom-messenger-frame {
    top: 28px!important;
    max-height: calc(100% - 24px)!important;
  }
}
</style>