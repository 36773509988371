<template>
  <v-layout row wrap>
    <v-flex lg3 xs6>
      <v-card height="90%" class="ma-2 stats-tile tile-sessions pointer" @click.stop="openDetails('Sessions Log', 'session')">
        <v-card-text class="d-flex">
          <h3>Sessions</h3>
          <v-spacer></v-spacer>
          <div class="text-xs-right">
            <VTooltipHandler id="session-tile-info" classes="stat-tooltip" icon="info" text="Total number of sessions over the selected filter and date range" />
          </div>
        </v-card-text>
        <v-card-text class="text-xs-right py-1">
          <h2 v-if="loading">
            <v-progress-circular indeterminate color="white" :size="24"></v-progress-circular>
          </h2>
          <h2 v-else id="sessions-tile">{{ totals.session | numeral("0,0") }}</h2>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex lg3 xs6>
      <v-card height="90%" class="ma-2 stats-tile tile-consumption pointer" @click.stop="openDetails('Consumption Log', 'consumptionkWh')">
        <v-card-text class="d-flex">
          <h3>Total Consumption</h3>
          <v-spacer></v-spacer>
          <div class="text-xs-right">
            <VTooltipHandler
              id="total-consumption-tile-info"
              classes="stat-tooltip"
              icon="info"
              text="Total Kilowatt hours consumed by all sessions over the selected filter and date range"
            />
          </div>
        </v-card-text>
        <v-card-text class="text-xs-right py-1">
          <h2 v-if="loading">
            <v-progress-circular indeterminate color="white" :size="24"></v-progress-circular>
          </h2>
          <h2 v-else id="total-consumption-tile">
            <template v-if="totals.consumptionkWh >= 1000">{{ Math.floor(totals.consumptionkWh) | numeral("0,0") }}</template>
            <template v-else>{{ totals.consumptionkWh | numeral("0,0.000") }}</template>
            <small class="ml-1">kWh</small>
          </h2>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex lg3 xs6>
      <v-card height="90%" class="ma-2 stats-tile tile-duration pointer" @click.stop="openDetails('Duration Log', 'durationHours')">
        <v-card-text class="d-flex">
          <h3>Total Duration</h3>
          <v-spacer></v-spacer>
          <div class="text-xs-right">
            <VTooltipHandler id="total-duration-tile-info" classes="stat-tooltip" icon="info" text="Total time span of all sessions over the selected filter and date range" />
          </div>
        </v-card-text>
        <v-card-text class="text-xs-right py-1">
          <h2 v-if="loading">
            <v-progress-circular indeterminate color="white" :size="24"></v-progress-circular>
          </h2>
          <h2 v-else id="total-duration-tile">{{ totals.duration | numeral("00:00:00") }}</h2>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex lg3 xs6>
      <v-card v-if="walletItems.length > 0" height="90%" class="ma-2 stats-tile tile-cost p-relative">
        <WalletsCarousel
          v-if="ready && walletItems"
          :wallet-items="walletItems"
          :loading="loading"
          title="Total Expense"
          tooltip="Total amount of money spent over the selected filter and date range"
          amount-key="expense"
        />
      </v-card>
      <v-card v-else height="90%" class="ma-2 stats-tile tile-cost pointer" @click.stop="openDetails('Expense', 'expense')">
        <v-card-text class="d-flex">
          <h3>Total Expense</h3>
          <v-spacer></v-spacer>
          <div class="text-xs-right">
            <VTooltipHandler id="total-expense-tile-info" classes="stat-tooltip" icon="info" text="Total amount of money spent over the selected filter and date range" />
          </div>
        </v-card-text>
        <v-card-text class="text-xs-right py-1">
          <h2 v-if="loading">
            <v-progress-circular indeterminate color="white" :size="24"></v-progress-circular>
          </h2>
          <h2 v-else id="total-expense-tile">N/A</h2>
        </v-card-text>
      </v-card>
    </v-flex>
    <TotalStatsGraph :selected="selectedStat" />
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import VTooltipHandler from "@/components/VTooltipHandler";
import WalletsCarousel from "@/components/WalletsCarousel";
import TotalStatsGraph from "@/components/modals/TotalStatsGraph";

export default {
  components: {
    WalletsCarousel,
    TotalStatsGraph,
    VTooltipHandler,
  },
  data() {
    return {
      ready: false,
      selectedStat: {},
      showTooltip: {
        session: false,
        expense: false,
      },
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      stats: (state) => state.stats || [],
      wallets: (state) => state.wallets || [],
    }),
    walletItems() {
      let wallets = JSON.parse(JSON.stringify(this.wallets));
      let expenses = this.stats.expenses || [];
      const sessionDatas = JSON.parse(JSON.stringify(this.$store.getters.sessions));

      const filteredCurrencies = sessionDatas.map((session) => session.wallet.currency);
      wallets = wallets.filter((wallet) => filteredCurrencies.includes(wallet.currency));

      for (let i = 0; i < wallets.length; i++) {
        const wallet = wallets[i];
        let expense = expenses.find((obj) => obj.wallet_id === wallet.id);
        wallets[i].expense = expense ? expense.total_cost : 0;
      }
      return wallets.sort((a, b) => b.expense - a.expense);
    },
    totals() {
      let stats = {
        session: this.stats.session || 0,
        consumptionkWh: this.stats.consumptionkWh || 0,
        duration: this.stats.duration || 0,
        cost: this.stats.cost || 0,
      };

      return stats;
    },
  },
  methods: {
    openDetails(name, field) {
      this.selectedStat = {};
      this.$nextTick(() => {
        this.selectedStat = {
          name,
          field,
          data: this.stats,
        };
      });
    },
  },
  watch: {
    walletItems() {
      this.$store.dispatch("setWalletItems", this.walletItems);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true;
    });
  },
};
</script>

<style lang="scss">
.stats-tile {
  position: relative;
  color: #ffffff !important;
  small {
    font-size: 0.5em;
  }
  h2 {
    font-weight: 300;
    font-size: 2.2em;
    @media only screen and (max-width: 598px) {
      font-size: 1.8em;
    }
  }
  h3 {
    @media only screen and (max-width: 598px) {
      font-size: 0.9em;
    }
  }
}

.tile-consumption {
  background: #039be5 !important;
}

.tile-duration {
  background: #7e57c2 !important;
}

.tile-sessions {
  background: #7cb342 !important;
}

.tile-cost {
  background: #ffb300 !important;
}

.stat-tooltip {
  color: #f2f2f2 !important;
  &:hover {
    color: #333333 !important;
  }
}
</style>
