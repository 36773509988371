<template>
  <v-flex d-flex>
    <v-autocomplete
      class="my-4"
      v-model="selected"
      :items="timezones"
      item-text="name"
      item-value="id"
      label="Time Zone"
      outline
      no-data-text="No timezone available"
    />
  </v-flex>
</template>

<script>
import timezones from "@/data/timezones.json";
export default {
  name: "TimezoneInput",
  props: {
    value: { default: null, required: true },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    timezones: () => timezones.map((v) => ({ name: v.text, id: v.value })),
  },
  watch: {
    selected(value) {
      this.$emit("input", value)
    },
  },
}
</script>
