<template>
  <v-autocomplete
    v-model="selected"
    :items="supportedCurrencies"
		v-bind="$attrs"
  />
</template>

<script>
import supportedCurrencies from "@/data/supportedCurrencies"
export default {
  name: "CurrencyInput",
  props: {
    value: { default: null, required: true },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    supportedCurrencies: () => supportedCurrencies.map(o => o.country + " (" + o.currency_code + ")").sort(),
  },
  watch: {
    selected(value) {
      this.$emit("input", value)
    },
  },
}
</script>
