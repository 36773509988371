<template>
  <v-form ref="addWifiForm" @submit="submit()">
    <v-text-field outline label="SSID" v-model="inputs.ssid" autocomplete="false" :rules="[rules.required]" required></v-text-field>
    <v-select v-model="inputs.security" :items="variables.networks.securities" item-text="name" item-value="data" label="Security" outline :rules="[rules.allowZero]"></v-select>
    <p class="pb-4 ml-2">
      <span class="font-weight-medium">WPA2-Personal</span> is most common for home networks<br />
      <span class="font-weight-medium">WPA2 Enterprise</span> is most common for business networks
    </p>
    <v-select
      v-if="inputs.security !== 0"
      v-model="inputs.encryption"
      :items="variables.networks.encryptions"
      item-text="name"
      item-value="data"
      label="Encryption"
      outline
      class="mt-4"
      :rules="[rules.allowZero]"
    ></v-select>
    <!-- <v-select v-model="inputs.channel" :items="variables.networks.channels" label="Channel" outline :rules="[rules.allowZero]"></v-select> -->
    <v-text-field
      v-if="inputs.security !== 0"
      outline
      label="Password"
      :append-icon="visiblePasswordInputs.password ? 'visibility' : 'visibility_off'"
      v-model="inputs.password"
      :type="visiblePasswordInputs.password ? 'text' : 'password'"
      autocomplete="false"
      :rules="[rules.required]"
      @click:append="visiblePasswordInputs.password = !visiblePasswordInputs.password"
      required
    ></v-text-field>
    <v-text-field
      v-if="inputs.security !== 0"
      outline
      label="Confirm Password"
      :append-icon="visiblePasswordInputs.confirm_password ? 'visibility' : 'visibility_off'"
      v-model="inputs.confirm_password"
      :error="inputs.password !== inputs.confirm_password"
      :type="visiblePasswordInputs.confirm_password ? 'text' : 'password'"
      autocomplete="false"
      :rules="[rules.required]"
      @click:append="visiblePasswordInputs.confirm_password = !visiblePasswordInputs.confirm_password"
      required
    ></v-text-field>

    <v-checkbox v-model="inputs.checkbox" required color="success" hide-details class="addwificheckbox">
      <template v-slot:label>
        <small style="text-align:justify">
          The device will automatically attempt to search for and connect to all WiFi networks added to the device.
        </small>
      </template>
    </v-checkbox>
    <v-layout xs12 class="mt-4">
      <v-flex xs12 style="display:flex">
        <v-btn @click="$emit('cancelled')" color="gray" :class="cancelButtonClass">{{ cancelButtonText }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" :class="confirmButtonClass" :disabled="!inputs.checkbox" @click="submit()">
          {{ confirmButtonText }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import mixin from "./../../mixins";
import variables from "./../../mixins/variables";
export default {
  mixins: [mixin, variables],
  props: {
    cancelButtonText: {
      default: "Cancel",
      type: String,
    },
    confirmButtonText: {
      default: "Confirm",
      type: String,
    },
    cancelButtonClass: {
      default: "v-btn--flat",
      type: String,
    },
    confirmButtonClass: {
      default: "v-btn--flat",
      type: String,
    },
  },
  data() {
    return {
      inputs: {
        ssid: "",
        security: 3,
        encryption: 2,
        channel: 0,
        password: "",
        confirm_password: "",
        checkbox: true,
      },
      visiblePasswordInputs: {
        password: false,
        confirm_password: false,
      },
    };
  },
  methods: {
    submit() {
      if (!this.$refs.addWifiForm.validate()) return false;
      if (this.inputs.password !== this.inputs.confirm_password) return false;
      this.$emit("submitted", this.inputs);
    },
  },
};
</script>
