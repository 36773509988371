<template>
  <v-flex 
    v-if="!!device"
    :class="gridClass"
  >
    <v-card
      class="session-tile session-history outlet-card ma-2"
      :class="cardClass"
    >
      <v-card-text
        class="pl-1 py-2 pr-0"
        @click.stop="handleClick"
      >
        <v-list three-line>
          <v-list-tile avatar class="d-flex ma-0">
            <div class="d-flex outlet-card__container">
              <div class="d-flex justify-center align-center outlet__container">   
                <v-list-tile-avatar>
                  <div class="plug-avatar plug-lists-avatar" :class="iconClass">
                    <img src="./../../assets/plug-off-always.png" v-if="device.plugmode === 0" />
                    <img src="./../../assets/plug-on-always.png" v-else-if="device.plugmode === 1" />
                    <img src="./../../assets/plug-on.png" v-else-if="device.active" />
                    <img src="./../../assets/plug-off.png" v-else />
                  </div> 
                </v-list-tile-avatar>
                <v-list-tile-content class="plug-tile-details pl-3">
                  <div class="plug-tile-details-text">
                    Device ID:
                    <span class="black--text">{{ device.identifier }}</span>
                  </div>
                  <div class="plug-tile-details-text">
                    Description:
                    <span class="black--text">{{ device.description }}</span>
                  </div>
                  <div 
                    v-if="device.plugmode > 1 && device.ispublic"
                    class="plug-tile-details-text"
                  >
                    Public Payment Plan:
                    <span class="black--text">{{ device.access_plan }}</span>
                  </div>
                  <slot name="append-body" :device="device" />
                </v-list-tile-content>
              </div>
            </div>
          </v-list-tile>
        </v-list>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DeviceCard",
  props: {
    identifier: {
      type: String,
      default: '', 
    },
    gridClass: {
      type: String,
      default: "md6 xs12"
    },
    cardClass: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters(["plugs"]),
    device() {
      return (this.plugs || []).find(p => p.identifier === this.identifier)
    },
    iconClass() {
      if (this.device.plugmode === 0) return "plug-avatar-off";
      else if (this.device.plugmode === 1) return "plug-avatar-on";
      else if (this.device.active) return "plug-avatar-active";
      return "plug-avatar-inactive";
    },
  },
  methods: {
    handleClick() {
      this.$emit("clicked")
    }
  }
  
};
</script>
<style lang="scss" scoped>
:not(.session-tile__selected) {
  &.session-tile__hasgroup {
    background-color: lighten(grey, 30%)!important;
    &-dark { 
      background-color: #787276;

      div, v.list { 
        background-color:#787276 !important;
      }
      .plug-tile-details-text {
        color: white!important;
        & > * {
          color: white!important;
        }
      }
    }
    div, .v-list {
      background-color: lighten(grey, 30%)!important;
    }
  }
}
</style>
