<template>
  <v-card class="ma-2">
    <v-card-title
      class="px-0"
    >
      <h3 class="ml-4" v-html="title" />
      <v-spacer />
      
      <DataExporter
        class="mr-4 mt-3"  
        title="Export Graph Data"
        :data="exportableCSV"
        :filename="`${deviceview.identifier}_${yAxisLabel}_${xAxisLabel}_${$moment.unix(timeRange.since).format('YYYYMMDDHHMM')}_${$moment.unix(timeRange.till).format('YYYYMMDDHHMM')}_${$moment().format('YYYYMMDDHHmmss')}.csv`"
      >
       <h3> Device: {{ deviceview.identifier }} </h3>
       <h3> Data: {{ xAxisLabel }} vs {{ yAxisLabel }} </h3>
       <h3> Date From: {{ $moment.unix(timeRange.since).tz(timezone).format("MMM Do, YYYY hh:mm A") }} </h3>
       <h3> Date To: {{ $moment.unix(timeRange.till).tz(timezone).format("MMM Do, YYYY hh:mm A") }} </h3>
      </DataExporter>

      <v-menu transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn
            flat
            small
            icon
            class="ma-0"
            style="right: 1rem;"
            v-on="on"
          >
            <v-icon>keyboard_arrow_down</v-icon>
          </v-btn>
        </template>
        <v-list class="v-list__actions">
          <v-list-tile @click.stop="expand = !expand">
            <v-list-tile-title>{{ expand ? "Hide" : "Show" }}</v-list-tile-title>
          </v-list-tile>
          <template v-if="actions.length > 0">
            <v-list-tile v-if="actions.includes('minimize')" @click="$emit('minimize')">
              <v-list-tile-title>Minimize</v-list-tile-title>
            </v-list-tile>
            <v-list-tile v-if="actions.includes('maximize')" @click="$emit('maximize')">
              <v-list-tile-title>Maximize</v-list-tile-title>
            </v-list-tile>
            <v-divider />
            <v-list-tile v-if="actions.includes('moveLeft')" @click="$emit('moveLeft')">
              <v-list-tile-title>Move Left</v-list-tile-title>
            </v-list-tile>
            <v-list-tile v-if="actions.includes('moveRight')" @click="$emit('moveRight')">
              <v-list-tile-title>Move Right</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-divider />
          <v-list-tile @click.stop="$emit('remove')">
            <v-list-tile-title>Remove</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu> 
    </v-card-title>
    <v-card-text v-if="expand" class="pa-0">
      <div class="ma-2">
        <Chart
          class="heartbeat-chart"
          :chartId="chartId"
          :options="chartOptions.options"
          :data="chartOptions.dataCollection"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Chart from "@/components/charts/Chart.vue";
import { camelCaseToCapitalize } from "@/library/helpers"
import { isNumber } from 'lodash-es';
import { mapGetters, mapState } from "vuex"
import DataExporter from "../modals/DataExporter.vue";
import { getTimezoneOffset } from '@/library/helpers';
import { v4 as uuidv4 } from "uuid"

export default {
  name: "HeartbeatGraph",
  components: {
    Chart,
    DataExporter,
  },
  props: {
    xAxis: {
      required: true,
      type: String,
    },
    yAxis: {
      required: true,
      type: String,
    },
    data: {
      required: true,
    },
    actions: {
      default: () => [],
      type: Array
    },
    timeRange: {
      type: Object,
      default: () => ({ since: 0, till: 0 }),
    },
  },
  data() {
    return {
      expand: true,
      xAxisData: [],
      yAxisData: []
    };
  },
  computed: {
    ...mapState({
      timezone: state => state.Global.timezone
    }),
    ...mapGetters("Admin", {
      deviceview: "formattedDeviceView",
    }),
    xAxisLabel() {
      return camelCaseToCapitalize(this.xAxis)
    },
    yAxisLabel() {
      return camelCaseToCapitalize(this.yAxis)
    },
    title() {
      const axisText = `${this.yAxisLabel} vs ${this.xAxisLabel}`
      let timeRangeText = `<small>(${this.$moment.unix(this.timeRange.since).tz(this.timezone).format("MMM Do, YYYY hh:mm A")} - ${this.$moment.unix(this.timeRange.till).tz(this.timezone).format("MMM Do, YYYY hh:mm A")})</small>`
      return `${axisText} ${timeRangeText}`
    },
    exportableCSV() {
      let text = 
        `Manager:,${this.deviceview.owner_username}\n` +
        `Device ID:,${this.deviceview.identifier}\n` +
        `Date From (YYYY-MM-DD HH:MM:SS):, ${this.$moment.unix(this.timeRange.since).tz(this.timezone).format("YYYY-MM-DD HH:MM:SS A")}\n` +
        `Date To (YYYY-MM-DD HH:MM:SS):,${this.$moment.unix(this.timeRange.till).tz(this.timezone).format("YYYY-MM-DD HH:MM:SS A")}\n` +
        `Time Zone (GMT):,${getTimezoneOffset()}\n\n` +
        `${this.yAxisLabel},${this.xAxisLabel}\n`

      for (let i = 0; i < this.xAxisData.length; i++) {
        let x = this.xAxisData[i] 
        if (this.xAxis == "timestamp") {
          x = this.$moment.unix(this.data[i].raw_timestamp).tz(this.timezone).format("YYYY-MM-DD HH:MM:SS A") 
        }
        const y = this.yAxisData[i]
        text += `${y},${x}\n` 
      }
      return text
    },
    chartOptions() {
      return {
        dataCollection: {
          labels: this.xAxisData,
          datasets: [
            {
              borderColor: "rgba(3,155,229,1)",
              backgroundColor: "rgba(3,155,229,.1)",
              data: this.yAxisData,
              fill: true,
            },
          ],
        },
        options: {
          elements: {
            line: {
              tension: 0, // disables bezier curves
            },
            point: {
              radius: 0,
            },
          },
          legend: {
            display: false,
          },
          scales: {
            y: {
              beginAtZero: false,
              ticks: {
                maxRotation: 45,
                minRotation: 0,
              },
              title: {
                fontSize: 12,
                display: true,
                labelString: this.yAxisLabel,
              },
            },
            x: {
              title: {
                display: true,
                labelString: this.xAxisLabel,
              },
              ticks: {
                mirror: false,
                maxRotation: 68,
                minRotation: 0,
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              intersect: false,
              callbacks: {
                title: () => "",
                label: () => "",
                afterBody: (tooltipItem, d) => {
                  let index = tooltipItem[0].dataIndex;
                  let x = this.xAxisData[index]
                  if (["timestamp"].includes(this.xAxis)) {
                    x = this.$moment.unix(this.data[index][`raw_${this.xAxis}`]).tz(this.timezone).format("MMM Do, YYYY hh:mm:ss A")
                  }
                  return [
                    `${this.xAxisLabel}: ${x}`,
                    `${this.yAxisLabel}: ${this.yAxisData[index]}`
                  ]
                },
              },
            },
            zoom: {
              limits: {
                x: {
                  min: "original",
                  max: "original",
                },
              },
              zoom: {
                wheel: {
                  enabled: true,
                  speed: 0.01
                },
                pinch: {
                  enabled: true
                },
                mode: 'x',
              },
              pan: {
                enabled: true,
                mode: 'x',
              }
            },
            corsair: {
              color: "rgba(3,155,229,1)",
              width: 2
            }
          }
        },
      };
    },
    chartId() {
      return uuidv4()
    },
  },
  methods: {
    calculateData() {
      this.xAxisData = []
      this.yAxisData = []
      for (let i = 0; i < (this.data || []).length; i++) {
        const d = this.data[i];
        if (!d.hasOwnProperty(this.xAxis) || !d.hasOwnProperty(this.yAxis)) continue
        if (!isNumber(d[this.yAxis])) continue

        this.xAxisData.push(d[this.xAxis])
        this.yAxisData.push(d[this.yAxis])
      }
    }
  },
  mounted() {
    this.calculateData()
  }
};
</script>

<style lang="scss">
.heartbeat-chart {
  height: 280px !important;
  min-width: 500px;
}
</style>
