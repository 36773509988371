<template>
  <v-layout row wrap>
    <v-flex sm6 xs6>
      <v-card class="ma-2 stats-tile tile-sessions">
        <agile v-if="ready" ref="statsTileCarousel" autoplay :options="statsTileCarouselOptions">
          <div class="slide pointer" ripple @click.stop="openDetails('Sessions Log', 'session')">
            <v-card-text class="d-flex">
              <h3>Total Sessions</h3>
              <v-spacer></v-spacer>
              <div class="text-xs-right">
                <v-icon
                  id="session-tile-info"
                  class="tooltip-icon stat-tooltip"
                  v-tooltip="{
                    content: 'Total number of user sessions<br>over the selected filter and date range',
                    placement: 'top-center',
                    trigger: 'click hover',
                  }"
                  >info</v-icon
                >
              </div>
            </v-card-text>
            <v-card-text class="text-xs-right py-1 px-4">
              <h2 v-if="loading">
                <v-progress-circular indeterminate color="white" :size="24"></v-progress-circular>
              </h2>
              <h2 v-else id="sessions-tile">{{ totals.session | numeral("0,0") }}</h2>
            </v-card-text>
          </div>
          <div class="slide pointer" ripple @click.stop="openDetails('Sessions Log', 'session')">
            <v-card-text class="d-flex">
              <h3>Total Users</h3>
              <v-spacer></v-spacer>
              <div class="text-xs-right">
                <v-icon
                  class="tooltip-icon stat-tooltip"
                  v-tooltip="{
                    content: 'Total number of user sessions as well as unique users over the selected filter and date range',
                    placement: 'top-center',
                    trigger: 'click hover',
                  }"
                  >info</v-icon
                >
              </div>
            </v-card-text>
            <v-card-text class="text-xs-right py-1 px-4">
              <h2 v-if="loading">
                <v-progress-circular indeterminate color="white" :size="24"></v-progress-circular>
              </h2>
              <h2 v-else id="total-users">{{ totals.user | numeral("0,0") }}</h2>
            </v-card-text>
          </div>
          <template slot="prevButton">
            <v-icon>navigate_before</v-icon>
          </template>
          <template slot="nextButton">
            <v-icon>navigate_next</v-icon>
          </template>
        </agile>
      </v-card>
    </v-flex>
    <v-flex sm6 xs6>
      <v-card class="ma-2 stats-tile tile-consumption pointer" @click.stop="openDetails('Consumption Log', 'consumptionkWh')">
        <v-card-text class="d-flex">
          <h3>Total Consumption</h3>
          <v-spacer></v-spacer>
          <div class="text-xs-right">
            <v-icon
              id="total-consumption-tile-info"
              class="tooltip-icon stat-tooltip"
              v-tooltip="{
                content: 'Total Kilowatt hours consumed by all sessions over the selected filter and date range',
                placement: 'top-center',
                trigger: 'click hover',
              }"
              >info</v-icon
            >
          </div>
        </v-card-text>
        <v-card-text class="text-xs-right py-1">
          <h2 v-if="loading">
            <v-progress-circular indeterminate color="white" :size="24"></v-progress-circular>
          </h2>
          <h2 v-else id="total-consumption-tile">
            <template v-if="totals.consumptionkWh >= 1000">{{ Math.floor(totals.consumptionkWh) | numeral("0,0") }}</template>
            <template v-else>{{ totals.consumptionkWh | numeral("0,0.000") }}</template>
            <small class="ml-1">kWh</small>
          </h2>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex sm6 xs6>
      <v-card class="ma-2 stats-tile tile-duration pointer" @click.stop="openDetails('Duration Log', 'durationHours')">
        <v-card-text class="d-flex">
          <h3>Total Duration</h3>
          <v-spacer></v-spacer>
          <div class="text-xs-right">
            <v-icon
              id="total-duration-tile-info"
              class="tooltip-icon stat-tooltip"
              v-tooltip="{
                content: 'Total time span of all user sessions<br>over the selected filter and date range',
                placement: 'top-center',
                trigger: 'click hover',
              }"
              >info</v-icon
            >
          </div>
        </v-card-text>
        <v-card-text class="text-xs-right py-1">
          <h2 v-if="loading">
            <v-progress-circular indeterminate color="white" :size="24"></v-progress-circular>
          </h2>
          <h2 v-else id="total-duration-tile">{{ totals.duration | numeral("00:00:00") }}</h2>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex sm6 xs6>
      <v-card v-if="walletItems.length > 0 && stats.revenues.length > 0" class="ma-2 stats-tile tile-cost p-relative">
        <WalletsCarousel v-if="ready && walletItems" :wallet-items="walletItems" :loading="loading" />
      </v-card>
      <v-card v-else height="90%" class="ma-2 stats-tile tile-cost p-relative" @click.stop="openDetails('Revenue', 'revenue')">
        <v-card-text class="d-flex">
          <h3>Total Revenue</h3>
          <v-spacer></v-spacer>
          <div class="text-xs-right">
            <v-icon
              id="revenue-tile-info"
              class="tooltip-icon stat-tooltip"
              v-tooltip="{
                content: 'Total earnings from all user sessions<br>over the selected filter and date range',
                placement: 'top-center',
                trigger: 'click hover',
              }"
              >info</v-icon
            >
          </div>
        </v-card-text>
        <v-card-text class="text-xs-right py-1">
          <h2 v-if="loading">
            <v-progress-circular indeterminate color="white" :size="24"></v-progress-circular>
          </h2>
          <h2 v-else id="total-revenue-tile">N/A</h2>
        </v-card-text>
      </v-card>
    </v-flex>
    <TotalStatsGraph :selected="selectedStat" />
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import WalletsCarousel from "@/components/WalletsCarousel";
import TotalStatsGraph from "@/components/modals/TotalStatsGraph";

export default {
  components: {
    WalletsCarousel,
    TotalStatsGraph,
  },
  data() {
    return {
      ready: false,
      statsTileCarouselOptions: {
        autoplay: true,
        autoplaySpeed: window.CAROUSEL_TIME,
      },
      selectedStat: {},
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      stats: (state) => state.stats || [],
      wallets: (state) => state.wallets || [],
    }),
    walletItems() {
      let wallets = JSON.parse(JSON.stringify(this.wallets));
      let revenues = this.stats.revenues || [];
      const sessionDatas = JSON.parse(JSON.stringify(this.$store.getters.sessions));

      const filteredCurrencies = sessionDatas.map((session) => session.wallet.currency);
      wallets = wallets.filter((wallet) => filteredCurrencies.includes(wallet.currency));

      for (let i = 0; i < wallets.length; i++) {
        const wallet = wallets[i];
        let revenue = revenues.find((obj) => obj.owner_wallet_id === wallet.id);
        wallets[i].revenue = revenue ? revenue.total_cost : 0;
      }
      return wallets.sort((a, b) => b.revenue - a.revenue);
    },
    totals() {
      let totals = {
        session: this.stats.session || 0,
        consumptionkWh: this.stats.consumptionkWh || 0,
        duration: this.stats.duration || 0,
        cost: this.stats.cost || 0,
        user: this.stats.user || 0,
      };
      return totals;
    },
  },
  methods: {
    openDetails(name, field) {
      this.selectedStat = {};
      this.$nextTick(() => {
        this.selectedStat = {
          name,
          field,
          data: this.stats,
        };
      });
    },
  },
  watch: {
    walletItems() {
      this.$store.dispatch("setWalletItems", this.walletItems);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true;
    });
  },
};
</script>

<style lang="scss">
@import "./../../styles/agile.scss";

.stats-tile {
  position: relative;
  color: #ffffff !important;
  small {
    font-size: 0.5em;
  }
  h2 {
    font-weight: 300;
    font-size: 2.2em;
    @media only screen and (max-width: 598px) {
      font-size: 1.8em;
    }
  }
  h3 {
    @media only screen and (max-width: 598px) {
      font-size: 0.9em;
    }
  }
}

.tile-consumption {
  background: #039be5 !important;
}

.tile-duration {
  background: #7e57c2 !important;
}

.tile-sessions {
  background: #7cb342 !important;
}

.tile-cost {
  background: #ffb300 !important;
}

.stat-tooltip {
  color: #f2f2f2 !important;
  &:hover {
    color: #333333 !important;
  }
}
</style>
