<template>
  <v-card class="ma-2 pa-3 p-relative" :class="{ 'mb-0': responsive, 'mb-3': !responsive }">
    <v-layout row wrap fill-height>
      <v-flex sm4 xs5 align-center>
        <total-plugs-pie class="align-center" :total="total" :colors="colors" :labels="labels" :raw-data="total_by_modes" />
      </v-flex>
      <v-flex sm8 xs7 class="pl-3">
        <v-layout row wrap class="total-labels" fill-height>
          <v-flex v-for="(mode, index) in labels" :key="index" xs6 class="total-plugs" align-center>
            <div class="ellipsis">
              <h2 :style="{ color: colors[index] }">{{ total_by_modes[index] | numeral("0,0") }}</h2>
              <span>{{ labels[index] }}</span>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-icon class="tooltip-icon stat-total-tooltip" v-tooltip="{ content: tooltip, trigger: 'click hover' }">
      info
    </v-icon>
  </v-card>
</template>

<script>
import TotalPlugsPie from "./../graphs/TotalPlugsPie";

export default {
  props: ["plugs"],
  components: {
    TotalPlugsPie,
  },
  data() {
    return {
      // commented to hide bluetooth 
      total_by_modes: [0, 0, 0/*, 0*/],
      colors: ["#00acc1", "#f44336", "#FFA500"/*, "#9a9a9a"*/],
      labels: ["Always On", "Always Off", "Smart"/*, "Bluetooth"*/],
      tooltip: `
		          <strong>Total registered devices and their power settings</strong>
		          <ul class="ma-0 mt-2 ml-3 pa-0">
		            <li class="mb-2">Smart: Allows you to control access, set billing rates,
		            monitor your<br>device's status and power consumption</li>
		            <li class="mb-2">Always On: Just like a regular device. Anyone can plug in</li>
		            <li>Always Off: Disables your device. Plugging in doesn't do anything</li>
		          </ul>`,
    };
  },
  computed: {
    responsive() {
      return this.$store.state.responsive;
    },
    total() {
      return this.plugs ? this.plugs.length : 0;
    },
  },
  methods: {
    processData() {
      let smart = 0;
      let on = 0;
      let off = 0;
      let bluetooth = 0;

      this.plugs.forEach((o) => {
        if (o.plugmode === 0) {
          off += 1;
        } else if (o.plugmode === 1) {
          on += 1;
        } else if (o.plugmode === 2) {
          smart += 1;
        } else {
          bluetooth++;
        }
      });

      this.total_by_modes = [on, off, smart, bluetooth, this.total];
    },
  },
  watch: {
    plugs() {
      if (this.plugs) this.processData();
    },
  },
  mounted() {
    if (this.plugs) this.processData();
  },
};
</script>

<style lang="scss" scoped>
.session-tile {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  overflow: hidden;
}

.total-labels {
  font-size: 15px;
  position: relative;
  border-radius: 3px;
  background: #f9f9f9;
  @media only screen and (max-width: 960px) {
    font-size: 0.9em;
  }
  div {
    width: 100%;
    text-align: center;
    background: #f9f9f9;
    overflow: hidden;
  }
}

.total-plugs {
  padding: 1.8em;
  @media only screen and (max-width: 600px) {
    font-size: 0.9em;
    padding: 1.4em;
  }
}

.stat-total-tooltip {
  position: absolute;
  top: 24px;
  right: 24px;
}
</style>
