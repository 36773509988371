<template>
  <v-dialog v-model="showModal" max-width="600">
    <v-card>
      <v-card-title>
        <h3>Contact Us</h3>
      </v-card-title>
      <v-card-text>
        <v-layout>
          <v-flex>
            <v-form ref="contactUsEmailForm">
              <v-text-field
                id="contactus-email"
                outline
                label="E-mail"
                name="contactus-email"
                prepend-inner-icon="account_circle"
                v-model="email"
                autocomplete="false"
                :rules="[validators.required, validators.email]"
                @keyup.enter.stop="submit"
                @keydown.space.prevent
                required
              />
            </v-form>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="gray"
          flat="flat"
          @click="showModal = false"
        >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="submit"
          color="green darken-1"
          flat="flat"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validators } from '@/library/helpers';
import { mapState } from "vuex"
export default {
  name: "IntercomEmailForm",
  data() {
    return {
      email: null,
    }
  },
  computed: {
    ...mapState({
      intercom: (state) => state.Global.intercom,
    }),
    validators: () => validators,
    showModal: {
      get() { return this.intercom.showEmailForm },
      set(value) {
        if (!value) this.email = null
        this.$store.commit("Global/setState", { intercom: { ...this.intercom, showEmailForm: value } })
      }
    },
  },
  methods: {
    submit() {
      if (this.$refs.contactUsEmailForm.validate()) {
        this.$store.commit("Global/setState", { intercom: { ...this.intercom, email: this.email, showEmailForm: false, show: true } })
        this.$nextTick(() => {
          this.email = null
        })
      }
    }
  },
};
</script>
