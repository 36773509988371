<template>
  <div class="chart-container">
    <canvas
      :id="chartId"
    />
  </div>
</template>

<script>
import {
  Chart, 
  registerables,
} from "chart.js"

import 'chartjs-adapter-moment';
import { corsair } from "./plugins"
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(...registerables, zoomPlugin);
export default {
  name: "Chart",
  props: {
    chartId: {
      type: String,
      default: "chart"
    },
    options: {
      type: Object | null,
      required: true
    },
    data: {
      type: Object | Array,
      required: true
    },
    type: {
      type: String,
      default: "line" // line, doughnut, bar
    },
  },
  data() {
    return {
      ctx: null
    }
  },
  watch: {
    options() {
      this.generateChart()
    },
    data() {
      this.generateChart()
    }
  },
  methods: {
    generateChart() {
      if (!!this.ctx) this.ctx.destroy()
      const plugins = [zoomPlugin]
      if (this.type === "line") {
        plugins.push(corsair)
      }
      this.ctx = new Chart(
        document.getElementById(this.chartId),
        {
          type: this.type,
          data: this.data,
          options: this.options,
          plugins
        },
      );
    }
  },
  mounted() {
    this.generateChart()
  }
}
</script>

<style scoped lang="scss">
.chart-container {
  height: 100%;
}
</style>