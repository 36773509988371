<template>
  <v-layout xs12 justify-center items-center>
    <v-flex class="plugs-tile-chart__container">
      <Chart
        class="plugs-tile-chart ma-2"
        :id="chartId"
        :options="options"
        :data="dataCollection || []"
        type="doughnut"
      />
    </v-flex>
    <v-flex class="plugs-tile-chart__total">
      <strong>{{ total }}</strong>
      <span>Total</span>
    </v-flex>
  </v-layout>
</template>

<script>
import Chart from "@/components/charts/Chart.vue";

export default {
  props: ["chartId", "colors", "labels", "rawData", "total"],
  components: {
    Chart,
  },
  data() {
    return {
      dataCollection: null,
      options: null,
    };
  },
  methods: {
    fillData() {
      let data = [this.percent(this.rawData[0]), this.percent(this.rawData[1]), this.percent(this.rawData[2]), this.percent(this.rawData[3])];

      this.dataCollection = {
        labels: this.labels,
        datasets: [
          {
            backgroundColor: this.colors,
            data,
          },
        ],
      };
      this.options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: ({ label, raw }) => `${label}: ${raw}%`
            },
          },
        }
      };
    },
    percent(val) {
      return ((val / this.total) * 100).toFixed(2);
    },
  },
  mounted() {
    this.fillData();
  },
  watch: {
    rawData() {
      this.$nextTick(() => {
        this.fillData();
      });
    },
  },
};
</script>

<style lang="scss">
.plugs-tile-chart {
  &__container {
    display: block;
  }
  &__total {
    position: absolute;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1500px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 1280px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 960px) {
      font-size: 20px;
    }
  }
}
</style>
