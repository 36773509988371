<template>
  <div>
    <v-card class="ma-2">
      <v-card-title
        ><h3 id="change-manager-email-tile">Change Manager E-mail Address</h3>
      </v-card-title>
      <v-alert :value="error" type="error" dismissible>
        {{ error }}
      </v-alert>
      <v-card-text class="text-xs-center">
        <v-form
          ref="changeManagerEmailForm"
          @submit="submit()"
        >
          <h4 class="text-xs-justify mb-3 mt-0"> 
            <strong> 
              <span class = "red--text">Warning:</span> Changing the manager's new e-mail adress will give the owner of the e-mail address all access permission to Plugzio management account. For security purposes, please make sure this change is intentional.
            </strong> 
          </h4>
    
          <p class="text-xs-justify subheading mb-4 mt-2">
            Current E-mail Address: <strong>{{ oldEmail }}</strong>
          </p>
          <v-text-field
            id="change-manager-email-field"
            :maxlength="50"
            outline
            label="New E-mail Address"
            v-model="newEmail"
            :rules="[validators.required, validators.email]"
            :error="error"
            :disabled="loading"
            required
          />
          <v-layout xs12>
            <v-spacer />
            <v-btn
              id="change-manager-email-save-button"
              class="plugzio-button mt-2"
              @click="submit()"
              outline
              round
              :loading="loading"
              :disabled="loading"
            >
              Submit
            </v-btn>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Api from "@library/apis/Api";
import validators from '@/library/helpers/validators';
import { mapState, mapGetters } from "vuex"

export default {
  name: "ChangeManagerEmailForm",
  data() {
    return {
      error: null,
      newEmail: null,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
      loading: (state) => state.loading,
      activeAdminView: (state) => state.Admin.activeView,
    }),
    ...mapGetters("Admin", {
      deviceview: "formattedDeviceView",
      viewAsOwner: "viewAsOwner",
    }),
    oldEmail() {
      if (this.role !== "admin") return null
      return (this.activeAdminView == "managerview" && !!this.viewAsOwner) ? this.viewAsOwner.username : null;
    },
    validators: () => validators
  },
  watch: { 
    "newEmail"(v, oldV) {
      this.$nextTick(() => {
        if (v && oldV != v) this.newEmail = this.newEmail.toLowerCase().trim();
      });
    },
  },
  methods: {
    submit() {
      if (this.$refs.changeManagerEmailForm.validate()) {
        this.error = null;
        this.$store.dispatch("loading", true);
        Api.adminOwnerRename({ oldName: this.oldEmail, newName: this.newEmail })
          .then(() => {
            this.$router.push({ path: "/admin/managerview", query: { search: this.newEmail } })
          })
          .catch((error) => {
            this.error = error.response.data;
          })
          .finally(() => {
            this.$store.dispatch("loading", false);
          });
      }
    },
  },
};
</script>
