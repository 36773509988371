<template>
  <v-text-field
    max-width="300"
    class="session-search mb-0 ml-2"
    v-model.lazy="search"
    :placeholder="!enableRegex ? 'Search' : 'Search RegEx'"
    autocomplete="false"
    :disabled="disabled"
    :prepend-icon="prependIcon"
  >
    <template v-if="enableRegex" v-slot:prepend-inner>
      <v-flex>
        <i class="search-slash-icon" style="bottom:-4px;position:relative">/</i>
      </v-flex>
    </template>
    <template v-slot:append>
      <v-flex align-self-center class="search-append-div">
        <i v-if="enableRegex" class="search-slash-icon mr-2" style="bottom:3px;position:relative">/</i>
        <v-icon
          @click="$emit('regexToggled', !enableRegex)"
          class="tooltip-icon"
          :color="enableRegex ? `blue lighten-2` : ''"
          v-tooltip="{
            content: tooltip,
            trigger: 'hover',
          }"
        >
          {{ icon }}
        </v-icon>
      </v-flex>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "RegexTextfield",
  props: {
    disabled: { default: false, type: Boolean },
    value: { default: null, required: true },
    tooltip: { 
      default: `Allows search results to be filtered using regular expression (RegEx) patterns. Regex flavour is JavaScript/ECMAScript.<br/>
                Examples:
                <ul>
                  <li>(Jan)|(Aug) - Pattern match results with either "Jan" or "Aug".</li>
                  <li>(My).{1,}(Unit) - Pattern match results that have pattern starting with "My and ending with "Unit" (e.g. "MySpecialUnit")</li>
                </ul>`,
      type: String
    },
    enableRegex: { default: false, type: Boolean },
    icon: { default: "settings_applications", type: String },
    prependIcon: { default: "", type: String },
  },
  data() {
    return {
      search: this.value,
    };
  },
  watch: {
    search: function(value) {
      this.$emit("input", value);
    },
  },
};
</script>
