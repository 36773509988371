<template>
  <v-card class="p-relative" :class="{ 'mb-0': responsive, 'mb-3': !responsive }">
    <v-layout row wrap fill-height>
      <v-flex x12 align-center text-xs-center>
        <v-progress-circular
          v-if="loading || globalLoading"
          class="loading-circle"
          size="40"
          indeterminate
          color="black"
          style="height: 200px"
        />
        <apexchart
          v-else
          :key="darkTheme"
          type="heatmap"
          height="204"
          :options="chartOptions"
          :series="chartSeries"
        />
        <div class="text-xs-right">
          <v-icon
            class="tooltip-icon mb-1 mr-3"
            v-tooltip="{
              content:
                'Consumption (kWh) distribution across days of the week vs. time. The darker the colors, the more electricity is being used during that time period.<br><br>Note that ongoing sessions are not taken into account on this chart.',
              placement: 'top-center',
              trigger: 'click hover',
            }"
          >
            info
          </v-icon>
        </div>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import VueApexCharts from "vue-apexcharts";
import SessionHelpers from "./../../library/helpers/SessionHelpers";
import { cloneDeep, debounce } from 'lodash-es';

const defaultChartSeries = [
  {
    name: "Sun",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  {
    name: "Sat",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  {
    name: "Fri",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  {
    name: "Thu",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  {
    name: "Wed",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  {
    name: "Tue",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  {
    name: "Mon",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
]
const mapDataSeries = (s) => ({ name: s.name, data: s.data.map((d) => d.toFixed(3)) })

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    filters: {
      type: Object,
    },
  },
  data() {
    return {
      chartSeries: cloneDeep(defaultChartSeries).map(mapDataSeries),
      loading: true,
    }
  },
  computed: {
    ...mapState({
      responsive: (state) => state.responsive,
      timezone: (state) => state.Global.timezone,
      globalLoading: (state) => state.loading,
    }),
    sessions() {
      const sessions = JSON.parse(JSON.stringify(this.$store.getters.sessions));
      if (!this.filters) return sessions;
      return SessionHelpers.filter(sessions, this.filters);
    },
    chartOptions() {
      const debouncedHoverHandler = debounce(() => {
        if (this.$gtm) {
          this.$gtm.trackEvent({
            event: "Custom Hover Event",
            "custom-hover-event": "heatmap-data-hover"
          });
        }
      }, 1000)
      return {
        chart: {
          height: 204,
          type: "heatmap",
          toolbar: {
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
          events: {
            dataPointMouseEnter: function() {
              debouncedHoverHandler()
            }
          }
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#4caf50"],
        xaxis: {
          position: "top",
          categories: [
            "12AM",
            "1AM",
            "2AM",
            "3AM",
            "4AM",
            "5AM",
            "6AM",
            "7AM",
            "8AM",
            "9AM",
            "10AM",
            "11AM",
            "12PM",
            "1PM",
            "2PM",
            "3PM",
            "4PM",
            "5PM",
            "6PM",
            "7PM",
            "8PM",
            "9PM",
            "10PM",
            "11PM",
          ],
          labels: {
            style: {
              fontSize: "10px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              if ([null, "", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].includes(value)) return value;
              return `<span style="color:#000000">${value} kWh</span>`;
            },
            style: {
              fontSize: "10px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400, 
              colors: this.darkTheme ? ["#fff"] : ["#5a5f60"],
            },
          },
        },
        theme: {
          mode: "light",
          monochrome: { 
            enabled: true, 
            color: "#4caf50", 
            shadeTo: "dark",
            shadeIntensity: 0.65
          }
        },
      }
    },
    darkTheme() {
      return ((this.$store.state.Global.preferences || {}).settings || {}).darkTheme || false
    }
  },
  methods: {
    async calculateDataSeries() {
      this.loading = true

      return new Promise(async (resolve) => {
        const series = cloneDeep(defaultChartSeries)

        for (let index = 0; index < this.sessions.length; index++) {
          const session = this.sessions[index];
          if (!session.endtime) continue;

          let starttime_hour = session.starttime - (session.starttime % 3600);
          let duration = 3600;
          
          const avg_consumption = (session.total_consumption || 0) / (session.duration_raw || 1); // in seconds
          
          while (starttime_hour <= session.endtime) {
            const date = this.$moment.unix(starttime_hour).tz(this.timezone);
            const day = date.day();
            const day_index = (Math.abs(day - 6) + 1) % 7;
            const hour = parseInt(date.format("HH"));

            const next_starttime_hour = starttime_hour + 3600;
            if (starttime_hour <= session.starttime) duration = (next_starttime_hour > session.endtime) ? session.endtime - session.starttime : next_starttime_hour - session.starttime;
            else duration = next_starttime_hour > session.endtime ? session.endtime - starttime_hour : 3600;

            series[day_index].data[hour] += (avg_consumption * duration) / 1000;
            
            starttime_hour = next_starttime_hour;
            duration = starttime_hour > session.endtime ? session.endtime % 3600 : 3600;
          }
        }
        this.chartSeries = series.map(mapDataSeries);
        resolve(this.chartSeries)
      }).finally(() => this.loading = false)
    }
  },
  watch: {
    sessions: {
      handler: function() {
        if (this.sessions.length === 0) {
          this.chartSeries = cloneDeep(defaultChartSeries).map(mapDataSeries);
          return
        }
        this.debouncedCalculateDataSeries()
      },
      deep: true,
    },
  },
  created() {
    this.debouncedCalculateDataSeries = debounce(async () => {
      await this.calculateDataSeries()
      this.loading = false  
    }, 300)
  },
  mounted() {
    this.debouncedCalculateDataSeries()
  }
};
</script>

<style lang="scss" scoped>
.session-tile {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  overflow: hidden;
}

.total-labels {
  font-size: 14px;
  position: relative;
  border-radius: 3px;
  background: #f9f9f9;
  @media only screen and (max-width: 960px) {
    font-size: 0.9em;
  }
  div {
    width: 100%;
    text-align: center;
    background: #f9f9f9;
    overflow: hidden;
  }
}

.total-plugs {
  padding: 1.8em;
  @media only screen and (max-width: 600px) {
    font-size: 0.9em;
    padding: 1.4em;
  }
}

.stat-total-tooltip {
  position: absolute;
  top: 24px;
  right: 24px;
}
</style>
