<template>
  <Chart
    ref="chart"
    :class="{ 'opacity-0': loading }"
    class="stat-tile-chart"
    :chartId="`${field}-bar-chart`"
    :options="options"
    :data="data"
    type="bar"
  />
</template>

<script>
import Chart from "@/components/charts/Chart.vue";
export default {
  components: {
    Chart,
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
    rawData: {
      default: () => [],
      type: Array,
    },
    field: {
      type: String,
    },
    xAxis: {
      type: String,
      default: "timeSet",
    },
  },
  computed: {
    fieldOptions() {
      return {
        session: {
          color: "#7CB342",
          label: "Sessions",
          tooltipFormatter: (v) => v + " session" + (v > 1 ? "s" : ""),
          yAxesTickCallback: (v) => this.formatNumber(v),
        },
        consumptionkWh: {
          color: "#039BE5",
          label: "kWh",
          tooltipFormatter: (v) => this.formatNumber(v.toFixed(3)) + " kWh",
          yAxesTickCallback: (v) => (v >= 1000 ? this.formatNumber(v.toFixed(0)) : v.toFixed(3)),
        },
        durationHours: {
          color: "#7E57C2",
          label: "Time (hours)",
          tooltipFormatter: (v) => v.toFixed(2),
          yAxesTickCallback: (v) => this.formatNumber(v),
        },
        revenue: {
          color: "#FFB300",
          label: "Revenue",
          tooltipFormatter: (v) => parseFloat(v || 0).toFixed(2),
          yAxesTickCallback: (v) => this.formatNumber(v.toFixed(2)),
        },
        expense: {
          color: "#FFB300",
          label: "Expense",
          tooltipFormatter: (v) => v.toFixed(2),
          yAxesTickCallback: (v) => this.formatNumber(v.toFixed(2)),
        },
      };
    },
    selectedFieldOption() {
      if (this.fieldOptions[this.field]) return this.fieldOptions[this.field];
      return {
        color: "rgba(0,160,0,0.5)",
        label: "",
        tooltipFormatter: (v) => v,
      };
    },
    data() {
      const labels = this.rawData.map((e) =>
        this.xAxis !== 'identifier'
        ? e[this.xAxis]
        : [`${e[this.xAxis]}`, `${e.description}`]
      );
      const data = this.rawData.map((e) => e[this.field]);

      return {
        labels,
        datasets: [
          {
            backgroundColor: this.selectedFieldOption.color,
            data,
          },
        ],
      };
    },
    options() {
      return {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            intersect: false,
            mode: "index",
            callbacks: {
              label: (tooltipItem) => this.selectedFieldOption.tooltipFormatter(tooltipItem.raw),
              title: (t) => typeof t === 'string' ? t : t[0].label
            },
          },
          zoom: {
            limits: {
              x: {
                min: "original",
                max: "original",
              },
            },
            zoom: {
              wheel: {
                enabled: true,
                speed: 0.01
              },
              pinch: {
                enabled: true
              },
              mode: 'x',
            },
            pan: {
              enabled: true,
              mode: 'x',
            }
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scaleShowValues: true,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              maxRotation: 45,
              minRotation: 0,
              callback: (value, index, values) => this.selectedFieldOption.yAxesTickCallback(value),
            },
            title: {
              fontSize: 12,
              display: true,
              text: this.selectedFieldOption.label,
            },
          },
          x: {
            beginAtZero: true,
            ticks: {
              autoSkip: false, // Don't skip xaxes label
              mirror: false,
              maxRotation: 68,
              minRotation: 0,
            },
          },
        },
        onClick: (evt, item) => {
          if (item.length > 0) this.$emit("clicked", item[0]);
        },
      };
    },
  },
  methods: {
    formatNumber(num) {
      var parts = num.toString().split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
    },
  },
};
</script>

<style lang="scss">
.stat-tile-chart {
  height: 50vh!important;
  margin-bottom: 15px;
}
</style>
