<template>
  <div :class="{ 'opacity-0-': loading }" style="overflow: auto">
    <Chart
      class="log-tile-chart"
      :chartId="chartId"
      :options="chartOptions.options"
      :data="chartOptions.dataCollection"
    />
  </div>
</template>

<script>
import Chart from "@/components/charts/Chart.vue";

export default {
  props: ["chartId", "chartLabel", "yLabel", "rawData", "dataField", "color", "fillColor", "loading", "timeformat"],
  components: {
    Chart,
  },
  computed: {
    data() {
      return {
        consumptions: this.rawData.map((e) => (e.consumption ? e.consumption : 0)),
        voltages: this.rawData.map((e) => (e.voltage ? e.voltage : 0)),
        currents: this.rawData.map((e) => (e.current ? e.current : 0)),
      };
    },
    chartOptions() {
      return {
        dataCollection: {
          labels: this.rawData.map((e) => e.timestamp || e.logitem_timestamp),
          datasets: [
            {
              borderColor: this.color,
              backgroundColor: this.fillColor,
              fill: true,
              data: this.data[this.dataField] || this.data.consumptions,
            },
          ],
        },
        options: {
          elements: {
            line: {
              tension: 0, // disables bezier curves
            },
            point: {
              radius: 0,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                maxRotation: 45,
                minRotation: 0,
                callback: (value, index, values) => {
                  if (this.dataField === 'consumptions') {
                    value = value / 1000;
                    return value >= 1000 ? this.formatNumber(value.toFixed(0)) : value.toFixed(3);
                  } 
                  return value.toFixed(this.dataField === 'voltages' ? 1 : 2);
                },
              },
              title: {
                fontSize: 12,
                display: true,
                text: this.yLabel,
              },
            },
            x: {
              type: "time",
              time: {
                parser: this.timeformat,
              },
              ticks: {
                mirror: false,
                maxRotation: 68,
                minRotation: 0,
              },
              title: {
                display: true,
                text: "Date",
              }
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              intersect: false,
              callbacks: {
                label: () => false,
                afterBody: (tooltipItem, d) => {
                  let index = tooltipItem[0].dataIndex;
                  let consumption = this.data.consumptions[index] || 0;
                  let voltage = this.data.voltages[index] || 0;
                  let current = this.data.currents[index] || 0;

                  consumption = consumption / 1000;
                  consumption = consumption ? consumption.toFixed(3) : 0;
                  voltage = voltage ? voltage.toFixed(1) : 0;
                  current = current ? current.toFixed(2) : 0;
                  const texts = ["Consumption: " + this.formatNumber(consumption) + " kWh"];
                  if (voltage >= 0) texts.push("Voltage: " + voltage + " V");
                  if (current >= 0) texts.push("Current: " + current + " A");

                  return texts;
                },
              },
            },
            zoom: {
              limits: {
                x: {
                  min: "original",
                  max: "original",
                },
                y: {
                  min: 0.06,
                },
              },
              zoom: {
                wheel: {
                  enabled: true,
                  speed: 0.01
                },
                pinch: {
                  enabled: true
                },
                mode: 'x',
              },
              pan: {
                enabled: true,
                mode: 'x',
              }
            },
            corsair: {
              color: this.color,
              width: 2
            }
          }
        },
      };
    },
  },
  methods: {
    formatNumber(num) {
      var parts = num.toString().split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
    },
  },
};
</script>

<style lang="scss">
.log-tile-chart {
  height: 280px !important;
  margin-bottom: 15px;
  min-width: 500px;
}
</style>
